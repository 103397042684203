@import 'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
@import 'colors';
@import 'common';

.header {
  color: #fff;
  margin-bottom: 2rem;
}

.edit-button {
  border-color: #00bc77;
  background-color: #00bc77;
  color: #fff;
  font-weight: bold;
  padding: 10px;
}
