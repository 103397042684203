@import 'colors';
@import 'common';
.sign-in-button {
  display: block;
  width: 100%;
  padding: 8px;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 1rem;
  border-color: $secondary-color;
  background-color: $secondary-color;
  color: $background-light;
}

.sign-in-content {
  box-sizing: border-box;
  background-color: white;
  width: 300px;
  margin: 0 auto;
  margin-top: 3rem;
  padding: 2rem;
}

.sign-in-icon {
  font-size: 5rem;
}

.input-remember {
  display: flex;
}

.input-remember label {
  margin-left: 0.25rem;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 1rem;
}

.input-wrapper label {
  font-weight: bold;
}

.input-wrapper input {
  padding: 5px;
  font-size: 1.2rem;
}
.alert {
  color: red;
  font-weight: bolder;
  margin-bottom: 10px;
}
