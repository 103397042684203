@import 'colors';
@import 'common';

.welcome-edit-container {
  display: flex;
  justify-content: center;
  button {
    width: 65%;
  }
  input {
    height: 2.5rem;
    margin: 10px 0;
  }
  div {
    display: flex;
    flex-direction: column;
    margin: 0 5px;
  }
}

.welcome-user-edit-left {
  align-items: flex-end;
}

.welcome-user-edit-right {
  align-items: flex-start;
}
