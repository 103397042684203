@import 'colors';
// @import 'https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.2.1/css/fontawesome.min.css';
html {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body #root {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;
}

.temp-div {
  font-size: 28px;
  font-weight: 900;
  text-align: center;
  vertical-align: center;
  width: 100%;
  height: 100%;
  margin: auto auto;
}
.home-div {
  margin-top: 18%;
}
button {
  font-size: 18px;
  padding: 10px 20px;
}
